/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  eqeqeq,
  import/no-extraneous-dependencies,
*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { stringify } from 'qs';
import superagent from 'superagent';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';
import Notification from 'common/components/notifications/legacy-notification';
import Modal from './modal';

const messages = defineMessages({
  handleError: {
    id: '',
    defaultMessage:
      'Sorry, we are unable to process your request at this time. Please try again later.',
    description: 'Team Page, error text after submit report',
  },
  handleResponse: {
    id: '',
    defaultMessage:
      'Your request is being processed. We will send you an email when the report is ready.',
    description: 'Team Page, error text after submit report',
  },
});

const noop = () => {};

export class TeamReporting extends React.Component {
  static propTypes = {
    canRequestUsage: PropTypes.bool.isRequired,
    csrfToken: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    intl: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);

    this.state = {
      modalActive: false,
      busy: false,
      canRequestUsage: props.canRequestUsage,
    };
  }

  handleError(/* error */) {
    this.closeModal(() => {
      Notification.displayNotification({
        class_name: 'error',
        text: this.props.intl.formatMessage(messages.handleError),
        display_time: 10000,
      });
    });
  }

  handleModalOpen() {
    this.setState({ modalActive: true });
  }

  handleModalClose(evt, cb) {
    this.closeModal(cb);
  }

  closeModal(cb = noop) {
    this.setState(
      {
        modalActive: false,
        busy: false,
      },
      cb,
    );
  }

  handleResponse(err) {
    if (err) {
      return this.handleError(err);
    }

    // Check for response.success?
    this.closeModal(() => {
      Notification.displayNotification({
        class_name: 'success',
        text: this.props.intl.formatMessage(messages.handleResponse),
        display_time: 10000,
      });
    });
  }

  handleSubmit(options) {
    const { csrfToken } = this.props;
    const url = HfReactHelper.urlHelper(
      'home/requestReport',
      HfReactHelper.HfSites.SITE_CODE_HS,
    );

    const data = stringify({
      options,
      csrf_token: csrfToken,
    });

    this.setState({
      error: undefined,
      successful: false,
      busy: true,
    });

    // If requesting usage report, disallow further usage report requests
    if (options.usage == true) {
      this.setState({ canRequestUsage: false });
    }

    return superagent
      .post(url)
      .type('form')
      .send(data)
      .on('request', () => this.setState({ busy: true }))
      .end(this.handleResponse);
  }

  render() {
    const { isEnabled } = this.props;
    const { busy, modalActive, canRequestUsage } = this.state;

    return (
      <span>
        <a
          className="team-page-more-seats"
          onClick={this.handleModalOpen}
          style={{ padding: '0' }}
        >
          <FormattedMessage
            id=""
            defaultMessage="DOWNLOAD A REPORT"
            description="Team Page, link on page"
          />
        </a>

        {/* This is not a DIG modal. */}
        {/* eslint-disable @dropbox/sign/dig-modal-aria-labelledby */}
        {modalActive && (
          <Modal
            busy={busy}
            allowedOptions={
              canRequestUsage ? ['usage', 'compliance'] : ['compliance']
            }
            isEnabled={isEnabled}
            onClose={this.handleModalClose}
            onSubmit={this.handleSubmit}
          />
        )}
      </span>
    );
  }
}

export default injectIntl(TeamReporting);
