import React from 'react';
// After this code is moved out to DPC, we can drop this import and uncomment the implementation.
// eslint-disable-next-line @dropbox/sign/isolate-folder
import * as baseFeatureFlags from 'js/sign-components/common/feature-flag';

export type SignerAppFeatureFlags = Partial<{
  /* eslint-disable camelcase */
  sign_services_20240325_progressive_checkout: boolean;
  sign_services_2025_02_27_fingerprint_sign_up: boolean;
  /* eslint-enable camelcase */
}>;

// const {
//   useContext: useFeatureFlags,
//   Provider,
// } = createSimpleDataContext<SignerAppFeatureFlags>('FeatureFlags');

export function FeatureFlagProvider({
  children,
  featureFlags,
}: React.PropsWithChildren<{ featureFlags: SignerAppFeatureFlags }>) {
  return (
    <baseFeatureFlags.FeatureFlagProvider featureFlags={featureFlags as any}>
      {children}
    </baseFeatureFlags.FeatureFlagProvider>
  );
}

export function useFeatureFlag(name: keyof SignerAppFeatureFlags) {
  return baseFeatureFlags.useFeatureFlag(name);
  // const context = useFeatureFlags();
  // return Boolean(context[name]);
}
