import { PAPEvent } from '@dropbox/pap-events/base/event';
// eslint-disable-next-line @dropbox/sign/isolate-folder
import * as productAnalyticsPipeline from 'js/sign-components/common/product-analytics-pipeline';

type AmplitudeUserId = string | number | null | undefined;

export const logPAPEvent = (
  event: PAPEvent,
  userId: AmplitudeUserId = undefined,
  foreignUserId: AmplitudeUserId = undefined,
  userProperties: object = {},
) =>
  productAnalyticsPipeline.logPAPEvent(
    event,
    userId,
    foreignUserId,
    userProperties,
  );
