type Options = {
  prefix: string;
  ignoredFunctions?: string[];
  interceptResult?: (
    prefix: string,
    propKey: string,
    args: any,
    returnValue: any,
  ) => any;
  interceptArgs?: (prefix: string, propKey: string, args: any) => any[];
};

const proxySymbol = Symbol('isProxy');
const isProxy = (obj: any) => {
  return Boolean(obj != null && obj[proxySymbol]);
};

export function makeRecursiveProxy<T extends object>(
  target: T,
  options: Options,
) {
  if (isProxy(target) || target == null) {
    return target;
  }

  // I normally would not recommend using `object` or `any`, but I don't think it's
  // worth making this perfect.
  const proxyHandler = (
    prefix = '',
    options: Options,
  ): ProxyHandler<object> => {
    const {
      interceptResult,
      ignoredFunctions = ['map', 'toString'],
      interceptArgs = (_prefix, _propKey, args) => args,
    } = options;

    return {
      set(target, propKey, value, receiver) {
        return Reflect.set(target, propKey, value, receiver);
      },
      get(target, propKey, receiver) {
        if (propKey === proxySymbol) {
          return true;
        }

        const targetValue = Reflect.get(target, propKey, receiver);

        if (typeof propKey === 'symbol' || ignoredFunctions.includes(propKey)) {
          return targetValue;
        }

        if (typeof targetValue === 'function') {
          const value = new Proxy(targetValue, {
            apply(target, thisArg, args) {
              const done = (returnValue: any) => {
                return (
                  interceptResult?.(prefix, propKey, args, returnValue) ??
                  returnValue
                );
              };
              let finalArgs = interceptArgs?.(prefix, propKey, args);
              finalArgs ??= args;

              const returnValue = target.apply(thisArg, finalArgs);
              if (
                typeof returnValue === 'object' &&
                typeof returnValue.then === 'function'
              ) {
                return returnValue.then(done).catch((error: Error) => {
                  return done({
                    type: 'error',
                    error: error?.message,
                  });
                });
              }
              return done(returnValue);
            },
          });
          // @ts-expect-error DPC_REMOVE
          target[propKey] = value;
          return value;
        } else if (typeof targetValue === 'object') {
          if (!isProxy(targetValue) && targetValue != null) {
            const value = new Proxy(
              targetValue,
              proxyHandler(`${prefix}.${propKey}`, options),
            );
            // @ts-expect-error DPC_REMOVE
            target[propKey] = value;
            return value;
          }

          return targetValue;
        } else {
          return targetValue;
        }
      },
    };
  };

  return new Proxy<T>(target, proxyHandler(options.prefix, options));
}
